import React from "react";
import { CircularProgress } from "@mui/material";

type Props = {
  size?: number | string;
};

/**
 * A base loader component.
 */
export const BaseLoader: React.FC<Props> = ({ size }) => (
  <CircularProgress color="primary" size={size} />
);
