import React from "react";
import { Box } from "@mui/material";
import { BaseLoader } from "./BaseLoader";

/**
 * A full page loader component.
 */
export const FullPageLoader = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    minHeight="100vh"
    boxSizing="border-box"
  >
    <BaseLoader />
  </Box>
);
